import React from 'react';
import { ParallaxProvider } from 'react-scroll-parallax';
import './App.css';
// components
import Nav from './components/Nav'
import Body from './components/Body';
import Gallery from './components/Gallery';
import Banner from './components/Banner';
// constants
import { aboutMeText, galleryContent } from './constants';
// images
const alaska = require('./images/alaska.jpeg');
const vancouver = require('./images/vancouver.jpeg')
const sahara = require('./images/sahara.jpeg')

function App() {
  return (
    <ParallaxProvider>
      <div className='App'>
        <Nav />
        <Banner images={vancouver} textContent='MINE' />
        <Body textContent={aboutMeText} />
        <Banner images={alaska} textContent='ALASKA' />
        <Gallery textContent={galleryContent} />
        <Banner images={sahara} textContent='SAHARA' />
        <Body textContent='what have i been up to even?' />
      </div>
    </ParallaxProvider>
  );
}

export default App;
