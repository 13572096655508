import React from 'react';
import '../App.css';

export default function Nav() {
  return (
    <div className='navigation'>
      <h1>MINE</h1>
      {/* <button>ABOUT</button>
      <button>CONTACT</button> */}
    </div>
  );
}

