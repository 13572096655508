import React from 'react';
import '../App.css';

export interface BodyType {
  textContent?: string;
}

export default function Body({ textContent }: BodyType) {
  return (
    <div className='body'>
      {textContent}
    </div>
  );
}

