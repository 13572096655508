import React from 'react';
import '../App.css';

export interface GalleryType {
  images?: string;
  textContent?: string;
}

export default function Gallery({ images, textContent }: GalleryType) {
  return (
    <div className='gallery'>
      {textContent}
    </div>
  );
}

