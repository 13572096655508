import React from 'react';
import { ParallaxBanner } from 'react-scroll-parallax';

import '../App.css'

export interface BannerType {
    images?: string;
    textContent?: string;
}

export default function Banner({ images, textContent }: BannerType) {
    return (
        <ParallaxBanner
            layers={[
                { image: images, speed: -20 },
                { image: images, speed: -10 },
            ]}
            style={{ aspectRatio: '2 / 1' }}
        >
            <div className="absolute inset-0 flex items-center justify-center">
                <h1 className="text-8xl text-white font-thin">{textContent}</h1>
            </div>
        </ParallaxBanner>
    );
}

